@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-cyrillic-ext-300-normal.8895cd13.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-cyrillic-300-normal.303b9afa.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-greek-ext-300-normal.73ef051e.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-greek-300-normal.962b7627.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-vietnamese-300-normal.6fb53ea7.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-ext-300-normal.51d7b0c4.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-300-normal.d07a8413.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-ext-400-normal.5baf44ce.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-400-normal.6d360dd5.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-ext-400-normal.3b199b49.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-400-normal.94705851.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-vietnamese-400-normal.b7170924.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-ext-400-normal.65ba6c38.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-400-normal.57844c26.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-ext-500-normal.216ff506.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-500-normal.4c9891c0.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-ext-500-normal.63a0634f.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-500-normal.9f44a402.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-vietnamese-500-normal.11348a9c.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-ext-500-normal.922e2b56.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-500-normal.da1d77f1.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-cyrillic-ext-700-normal.0921acac.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-cyrillic-700-normal.96126479.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-greek-ext-700-normal.00d528bb.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-greek-700-normal.bc3ad99c.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-vietnamese-700-normal.a1447a12.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-ext-700-normal.372a1858.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-700-normal.0e98339e.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-cyrillic-ext-400-normal.dcb520ee.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-cyrillic-400-normal.638e826e.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-greek-400-normal.dd843e41.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-vietnamese-400-normal.306e7635.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-latin-ext-400-normal.d9409874.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto Mono;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-mono-latin-400-normal.cf1eee5f.woff2") format("woff2"), url("roboto-mono-all-400-normal.364ec368.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.jtk-connector {
  overflow: visible;
}

.jtk-node {
  position: absolute;
}

.jtk-group {
  position: absolute;
  overflow: visible;
}

[data-jtk-group].jtk-group-collapsed [data-jtk-managed] {
  display: none;
}

[data-jtk-group-content] {
  position: relative;
}

.katavorio-clone-drag {
  pointer-events: none;
}

.jtk-surface {
  cursor: move;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  touch-action: none;
  position: relative;
  overflow: hidden !important;
}

.jtk-surface-direct-render {
  overflow: hidden !important;
}

.jtk-surface-panning {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.jtk-surface-canvas {
  overflow: visible !important;
}

.jtk-surface-droppable-node {
  touch-action: none;
}

.jtk-surface-nopan {
  cursor: default;
  overflow: scroll !important;
}

.jtk-surface-tile {
  -o-transition: opacity .3s ease .15s;
  border: none;
  outline: none;
  margin: 0;
  transition: opacity .3s .15s;
}

.jtk-lasso {
  opacity: .5;
  z-index: 20000;
  background-color: #f5f5f5;
  border: 2px solid #3177b8;
  display: none;
  position: absolute;
}

.jtk-lasso-select-defeat * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.jtk-lasso-mask {
  z-index: 20000;
  opacity: .5;
  background-color: #07234e;
  display: none;
  position: fixed;
  inset: 0;
}

.jtk-surface-selected-element {
  border: 2px dotted #c7726c !important;
}

.jtk-surface-pan {
  opacity: .4;
  text-align: center;
  cursor: pointer;
  z-index: 2;
  -o-transition: background-color .15s ease-in;
  background-color: azure;
  transition: background-color .15s ease-in;
}

.jtk-surface-pan-top, .jtk-surface-pan-bottom {
  width: 100%;
  height: 20px;
  justify-content: center;
  display: flex;
}

.jtk-surface-pan-top:hover, .jtk-surface-pan-bottom:hover, .jtk-surface-pan-left:hover, .jtk-surface-pan-right:hover {
  opacity: .6;
  color: #fff;
  background-color: #3177b8;
  font-weight: bold;
}

.jtk-surface-pan-left, .jtk-surface-pan-right {
  width: 20px;
  height: 100%;
  align-items: center;
  display: flex;
}

.jtk-surface-pan-active, .jtk-surface-pan-active:hover {
  background-color: #f76258;
}

.jtk-miniview {
  width: 125px;
  height: 125px;
  opacity: .8;
  background-color: #0000;
  border: 2px solid #d4d8dc;
  border-radius: 4px;
  position: relative;
  overflow: hidden !important;
}

.jtk-miniview-panner {
  opacity: .4;
  cursor: move;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  background-color: #4f6f7e;
  border: 5px dotted #f5f5f5;
}

.jtk-miniview-panning {
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.jtk-miniview-element {
  background-color: #607a86;
  position: absolute;
}

.jtk-miniview-group-element {
  background: none;
  border: 3px solid #000;
}

.jtk-miniview-collapse {
  color: #f5f5f5;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  top: -1px;
  right: 3px;
}

.jtk-miniview-collapse:before {
  content: "‒";
}

.jtk-miniview-collapsed {
  height: 22px;
  width: 21px;
  background-color: #449ea6;
  border-radius: 4px;
  margin-right: 0;
  padding: 4px;
}

.jtk-miniview-collapsed .jtk-miniview-element, .jtk-miniview-collapsed .jtk-miniview-panner {
  visibility: hidden;
}

.jtk-miniview-collapsed .jtk-miniview-collapse:before {
  content: "+";
}

.jtk-miniview-collapse:hover {
  color: #e4f013;
}

.jtk-draw-skeleton {
  opacity: .8;
  outline: 2px solid #84acb3;
  position: absolute;
  inset: 0;
}

.jtk-draw-handle {
  width: 7px;
  height: 7px;
  background-color: #84acb3;
  position: absolute;
}

.jtk-draw-handle-tl {
  cursor: nw-resize;
  top: 0;
  left: 0;
}

.jtk-draw-handle-tr {
  cursor: ne-resize;
  top: 0;
  right: 0;
}

.jtk-draw-handle-bl {
  cursor: sw-resize;
  bottom: 0;
  left: 0;
}

.jtk-draw-handle-br {
  cursor: se-resize;
  bottom: 0;
  right: 0;
}

.jtk-draw-drag {
  width: 20px;
  height: 20px;
  cursor: move;
  background-color: #84acb3;
  margin-top: -10px;
  margin-left: -10px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
}

.jtk-drag-select-defeat * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

/*# sourceMappingURL=index.4b3a79ba.css.map */
